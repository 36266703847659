import {
    Modal,
    Form,
    Row,
    Col,
    Input,
    Select,
    Divider,
    Tag,
    FormInstance,
    Spin,
    Button,
    Popover,
} from 'antd';
import styles from './DbParametersModal.module.less';
import AppTable from '../AppTable';
import { useAppSelector } from '../../redux/hooks';
import {
    getDatabaseConfigurationOptions,
    getDatabaseVendorOptions,
    getDatabaseVersionOptions,
} from '../../core/helpers';
import { useEffect, useState } from 'react';
import DbParameterGroupService from '../../services/DbParameterGroupService';
import { DBParameterDefaults } from '../../types/DBparameterDefaults';
import { DbParameterGroup } from '../../types/DbParameterGroup';
import UpdateDbParametersPopover from './UpdateDbParametersPopover';
import AppLoading from '../AppLoading';

interface DbParameterFormType {
    [x: string]: any;
    parameter: string;
    currentValue: string;
    datastores: string[];
    description?: string;
}

interface DbParametersModalProps {
    visible: boolean;
    onSubmit: (formData: any) => Promise<void>;
    setVisible: (visible: boolean) => void;
    handleCancel: () => void;
    operation: 'create' | 'edit';
    record?: Partial<DbParameterGroup>;
    form: FormInstance;
    disableFields: boolean;
}

interface OptionType {
    value: string;
    label: string;
}

const DbParametersModal: React.FC<DbParametersModalProps> = ({
    visible,
    onSubmit,
    operation,
    setVisible,
    record,
    handleCancel,
    form,
    disableFields,
}) => {
    const { deploymentOptions } = useAppSelector(
        (state) => state.deploymentOptions
    );

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dbVendor, setDbVendor] = useState<string>();
    const [dbVersion, setDbVersion] = useState<OptionType>();
    const [dbVersionOptions, setDbVersionOptions] = useState<OptionType[]>([]);
    const [filteredTableData, setFilteredTableData] = useState<
        DbParameterFormType[]
    >([]);
    const [dbConfigurationOptions, setDbConfigurationOptions] = useState<
        OptionType[]
    >([]);
    const [dbConfiguration, setDbConfiguration] = useState<OptionType>();
    const [tableForm] = Form.useForm();
    const [tableData, setTableData] = useState<DbParameterFormType[]>([]);
    const [datastoreList, setDatastoreList] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const databaseVendorOptions = getDatabaseVendorOptions(
        deploymentOptions?.getDatabaseVendors() || []
    );

    const handleDBVendorChange = async (value: string) => {
        const selectedDbVendor =
            deploymentOptions?.getDatabaseVendorByCode(value);
        setDbVendor(value);
        setDbVersion(undefined);
        setDbConfiguration(undefined);
        form.setFieldsValue({
            version: undefined,
            configuration: undefined,
        });

        if (selectedDbVendor?.name) {
            processDbVendorOptions(selectedDbVendor.name);
            processDbConfigurationsOptions(selectedDbVendor.name);
        }
        getDBParameters(value);
    };

    const processDbVendorOptions = (vendorName: string | undefined) => {
        if (vendorName) {
            const dbVersions = getDatabaseVersionOptions(
                deploymentOptions?.getDatabaseVendors(),
                vendorName
            );
            setDbVersionOptions(dbVersions);
            if (!record) {
                setDbVersion(dbVersions[0]);
                form.setFieldValue('version', dbVersions[0].value);
            }
        }
    };

    const processDbConfigurationsOptions = (vendorName: string | undefined) => {
        if (vendorName) {
            const dbConfigurations = getDatabaseConfigurationOptions(
                deploymentOptions?.getDatabaseVendors(),
                vendorName
            );
            setDbConfigurationOptions(dbConfigurations);
        }
    };

    const getDBParameters = async (value: string) => {
        setIsLoading(true);
        try {
            const dbParametersDefaults: DBParameterDefaults =
                await DbParameterGroupService.getDefaultDbParameterGroup(value);
            const dbParameters: any[] = [];
            for (const [key, value] of Object.entries(
                dbParametersDefaults.getAllParameters()
            )) {
                dbParameters.push({
                    parameter: key,
                    currentValue: value.value,
                    defaultValue: value.default_value,
                    description: value.description,
                });
            }
            setTableData(dbParameters);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const processDbParametersForTable = (dbparameters: any) => {
        if (!dbparameters) return;
        const dbParametersForm: any[] = [];
        for (const [key] of Object.entries(dbparameters)) {
            dbParametersForm.push({
                parameter: key,
                currentValue: dbparameters[key].currentValue,
                defaultValue: dbparameters[key].defaultValue,
                description: dbparameters[key].description,
            });
        }
        setTableData(dbParametersForm);
    };

    useEffect(() => {
        if (dbVersionOptions?.length === 1) {
            form.setFieldValue('version', dbVersionOptions[0].value);
            setDbVersion(dbVersionOptions[0]);
        }
        if (dbConfigurationOptions?.length === 1) {
            form.setFieldValue(
                'configuration',
                dbConfigurationOptions[0].value
            );
            setDbConfiguration(dbConfigurationOptions[0]);
        }
    }, [dbVersionOptions, dbConfigurationOptions, form]);

    useEffect(() => {
        if (record) {
            processDbVendorOptions(record?.databaseVendor);
            processDbConfigurationsOptions(record?.databaseVendor);
            processDbParametersForTable(record.dbParameters);

            const fieldsToSet = {
                groupName: record.name,
                description: record.description,
                vendor: record.databaseVendor,
                version: record.databaseVersion,
                configuration: record.databaseType,
            };

            form.setFieldsValue(fieldsToSet);
            if (record.dbParameters) {
                form.setFieldsValue(record.dbParameters);
            }
            setDatastoreList(record.dataStores || []);
            setDbVendor(record.databaseVendor);
        }
    }, [record, form, visible]);

    const handleOk = async () => {
        try {
            setIsSubmitting(true);
            const [formValues, tableValues] = await Promise.all([
                form.validateFields(),
                tableForm.validateFields(),
            ]);

            const updatedTableData = updateTableDataWithFormValues(tableValues);

            const submitData = {
                name: formValues.groupName,
                description: formValues.description,
                databaseVendor: formValues.vendor,
                databaseVersion: formValues.version,
                dataStores: datastoreList,
                configuration: formValues.configuration,
                dbParameters: updatedTableData,
            };
            await onSubmit(submitData);
            form.resetFields();
            tableForm.resetFields();
            setVisible(false);
        } catch (error) {
            console.error('Form validation or submission failed:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const updateTableDataWithFormValues = (
        formTableValues: Record<string, any>
    ) => {
        const updatedTableData = tableData.map((item) => {
            const updatedValues = formTableValues[item.parameter];
            const filteredItem = filteredTableData.find(
                (filtered) => filtered.parameter === item.parameter
            );

            if ((filteredItem && updatedValues) || updatedValues) {
                return {
                    ...item,
                    currentValue: updatedValues,
                    isActive: updatedValues.isActive,
                };
            }
            return item;
        });
        return updatedTableData.reduce((acc, item) => {
            acc[item.parameter] = item.currentValue;
            return acc;
        }, {} as Record<string, any>);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value.toLowerCase();
        const filtered = tableData.filter(
            (item) =>
                item.parameter.toLowerCase().includes(searchValue) ||
                item.currentValue.toLowerCase().includes(searchValue) ||
                item.description?.toLowerCase().includes(searchValue)
        );
        setFilteredTableData(filtered);
    };

    const columns = [
        {
            title: 'Parameter',
            dataIndex: 'parameter',
            width: 200,
        },
        {
            title: 'Current Value',
            dataIndex: 'currentValue',
            width: 200,
            render: (text: string, record: DbParameterFormType) => (
                <Form.Item
                    className={styles.DbParameterFormInput}
                    name={record.parameter}
                    initialValue={text}
                    rules={[{ required: true, message: 'Required' }]}
                >
                    <Input className={styles.DbParameterFormInput} />
                </Form.Item>
            ),
        },
        {
            title: 'Default Value',
            dataIndex: 'defaultValue',
            width: 200,
            render: (text: string) => (
                <span className={styles.BreakDefaultValue}>{text}</span>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: 200,
        },
    ];

    return (
        <Modal
            title={
                operation === 'create'
                    ? 'Create new DB parameter group'
                    : 'Edit DB parameter group'
            }
            open={visible}
            footer={
                <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    {operation === 'create' && (
                        <Button type="primary" onClick={handleOk}>
                            Create
                        </Button>
                    )}
                    {operation === 'edit' && (
                        <UpdateDbParametersPopover
                            handleCancel={handleCancel}
                            handleOk={handleOk}
                        />
                    )}
                </>
            }
            onCancel={() => setVisible(false)}
            width={1100}
            confirmLoading={isSubmitting}
            maskClosable={!isSubmitting}
        >
            <Spin spinning={isSubmitting}>
                <Form
                    layout="vertical"
                    className={styles.DbParameterForm}
                    form={form}
                >
                    <Row gutter={[16, 16]}>
                        <Col
                            xs={24}
                            sm={24}
                            md={operation === 'create' ? 24 : 12}
                        >
                            <Row gutter={[16, 16]}>
                                <Col
                                    xs={8}
                                    sm={8}
                                    md={operation === 'create' ? 4 : 8}
                                >
                                    <Form.Item
                                        label="Group name"
                                        name="groupName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Group name is required',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Name your group" />
                                    </Form.Item>
                                </Col>
                                <Col
                                    sm={16}
                                    xs={16}
                                    md={operation === 'create' ? 9 : 16}
                                >
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                    >
                                        <Input placeholder="Enter a description" />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={8}
                                    sm={8}
                                    md={operation === 'create' ? 4 : 8}
                                >
                                    <Form.Item
                                        label="Vendor"
                                        name="vendor"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vendor is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={disableFields}
                                            options={databaseVendorOptions}
                                            onChange={handleDBVendorChange}
                                            value={dbVendor}
                                            placeholder="Select a vendor"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={5}
                                    sm={5}
                                    md={operation === 'create' ? 3 : 5}
                                >
                                    <Form.Item
                                        label="Version"
                                        name="version"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Version is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={disableFields}
                                            options={dbVersionOptions}
                                            value={dbVersion?.value}
                                            placeholder="Select a version"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={11}
                                    sm={11}
                                    md={operation === 'create' ? 4 : 11}
                                >
                                    <Form.Item
                                        label="Configuration"
                                        name="configuration"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Configuration is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={disableFields}
                                            options={dbConfigurationOptions}
                                            value={dbConfigurationOptions}
                                            placeholder="Select a configuration"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={operation === 'edit' ? 12 : 0}>
                            Associated data stores
                            <Row gutter={[16, 16]}>
                                {datastoreList?.map(
                                    (dataStore: any, index: number) => (
                                        <Col key={dataStore}>
                                            <Tag
                                                color={'green'}
                                                closable={false}
                                                className={
                                                    styles.DbParametersModalTag
                                                }
                                            >
                                                {dataStore.name}
                                            </Tag>
                                        </Col>
                                    )
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Form>
                <Divider />
                <Form layout="vertical" className={styles.DbParameterForm}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Search" name="search">
                                <Input
                                    placeholder="Search"
                                    onChange={handleSearch}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Form form={tableForm}>
                    {isLoading ? (
                        <AppLoading />
                    ) : (
                        <AppTable
                            columns={columns}
                            data={
                                filteredTableData.length > 0
                                    ? filteredTableData
                                    : tableData
                            }
                            rowKey="parameter"
                            pagination={false}
                            expandable={false}
                        />
                    )}
                </Form>
            </Spin>
        </Modal>
    );
};

export default DbParametersModal;
