export type DbParameterValue = {
    value: string;
    description: string;
    type: 'number' | 'text';
    validation_options: string;
    default_value: string;
};

export type DbParameterDefaultsProps = {
    parameters: {
        [key: string]: DbParameterValue;
    };
    status: 'pending' | string;
    updated_at: string;
};

export interface DBParameterDefaultsInterface {
    parameters: { [key: string]: DbParameterValue };
    status: string;
    updatedAt: string;
}

export class DBParameterDefaults implements DBParameterDefaultsInterface {
    readonly parameters: { [key: string]: DbParameterValue };
    readonly status: string;
    readonly updatedAt: string;

    constructor(response: DbParameterDefaultsProps) {
        this.parameters = response.parameters;
        this.status = response.status;
        this.updatedAt = response.updated_at;
    }

    getParameter(key: string): DbParameterValue | undefined {
        return this.parameters[key];
    }

    getAllParameters(): { [key: string]: DbParameterValue } {
        return this.parameters;
    }

    getParameterKeys(): string[] {
        return Object.keys(this.parameters);
    }
}
