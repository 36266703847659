import {
    Modal,
    Form,
    Select,
    Row,
    Col,
    Button,
    Typography,
    FormInstance,
    Spin,
} from 'antd';
import CcxLogo from '../../../ccx/common/CcxLogo';
import styles from './DatastoreSettingsDbParameters.module.less';
import { mapDbParameterGroupsToOptions } from '../../../../utils/dbParameterGroups';
import { useEffect, useState } from 'react';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import { DbParameterGroup } from '../../../../types/DbParameterGroup';
import DbParameterGroupService from '../../../../services/DbParameterGroupService';

interface AssignGroupModalProps {
    readonly visible: boolean;
    readonly form: FormInstance;
    readonly setVisible: (visible: boolean) => void;
    readonly dataStore: DeploymentsItem | undefined;
    readonly onSubmit: (
        groupSelected: DbParameterGroup,
        dataStoreUUID: string
    ) => void;
}

const PER_PAGE = 100;
const PAGE = 1;

const AssignGroupModal: React.FC<AssignGroupModalProps> = ({
    visible,
    setVisible,
    form,
    dataStore,
    onSubmit,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [dbParametersList, setDbParametersList] = useState<
        DbParameterGroup[]
    >([]);

    const [selectedGroup, setSelectedGroup] = useState<
        DbParameterGroup | undefined
    >(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = () => {
        if (!dataStore || !selectedGroup) return;

        try {
            setIsSubmitting(true);
            onSubmit(selectedGroup, dataStore.dataStoreUuid);
        } catch (error) {
            console.error('Failed to submit:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchDbParameters();
    }, []);

    const fetchDbParameters = async () => {
        try {
            setIsLoading(true);
            const fetchDbParameters =
                await DbParameterGroupService.getDbParameterGroups(
                    PER_PAGE,
                    PAGE
                );
            setDbParametersList(fetchDbParameters.parameterGroups);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const mapDbParametersGroup = () => {
        if (!dataStore || !dbParametersList) return [];
        return mapDbParameterGroupsToOptions(
            dbParametersList,
            dataStore.getDatabaseVendor(),
            dataStore.getDatabaseVersion()
        );
    };

    const onSelectDbParametersGroup = (value: string) => {
        if (!dbParametersList) return;

        const selectedGroup = dbParametersList.find(
            (group: DbParameterGroup) => group.uuid === value
        );

        setSelectedGroup(selectedGroup);
        form.setFieldValue(
            'dbParametersGroup',
            selectedGroup ? value : undefined
        );
    };

    const modalContent = (
        <>
            <Form layout="vertical" form={form}>
                <Form.Item
                    name="dbParametersGroup"
                    label="DB parameter group"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a DB parameter group!',
                        },
                    ]}
                >
                    <Select
                        className={styles.DbParametersGroupSelect}
                        options={mapDbParametersGroup()}
                        onChange={onSelectDbParametersGroup}
                        disabled={isLoading || isSubmitting}
                        placeholder="Select a parameter group"
                    />
                </Form.Item>
            </Form>
            {selectedGroup && dataStore && (
                <>
                    <Typography.Text>
                        The group will be assigned to:
                    </Typography.Text>
                    <Row className={styles.AddDBParametersButton}>
                        <Col span={4} className={styles.DbParametersSelfCenter}>
                            <CcxLogo
                                logo={dataStore.getDbIconName()}
                                size="large"
                            />
                        </Col>
                        <Col span={20}>
                            <Typography.Text strong>
                                {selectedGroup.name}
                            </Typography.Text>
                            <Typography.Paragraph>
                                Vendor and version:{' '}
                                {selectedGroup.databaseVendor +
                                    ' ' +
                                    selectedGroup.databaseVersion}
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );

    return (
        <Modal
            open={visible}
            title="Assign DB parameter group"
            onCancel={() => setVisible(false)}
            footer={
                <Row justify="space-between">
                    <Col span={4} className={styles.DbParametersSelfCenter}>
                        <Typography.Text type="danger">*</Typography.Text>
                        Required
                    </Col>
                    <Col span={20}>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={
                                !selectedGroup || isLoading || isSubmitting
                            }
                            loading={isSubmitting}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            }
            closable={!isSubmitting}
            maskClosable={!isSubmitting}
        >
            <Spin spinning={isLoading}>{modalContent}</Spin>
        </Modal>
    );
};

export default AssignGroupModal;
